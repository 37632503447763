import React, { useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [files, setFiles] = useState([]);
  const [quality, setQuality] = useState(80);
  const [format, setFormat] = useState("webp");
  const [loading, setLoading] = useState(false);
  const [zipFileUrl, setZipFileUrl] = useState(null);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleQualityChange = (e) => {
    setQuality(e.target.value);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      alert("Please select at least one file!");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });
    formData.append("quality", quality);
    formData.append("format", format);

    try {
      const response = await axios.post(
        "https://img-to-webp-six.vercel.app/convert/",
        formData,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      setZipFileUrl(url);
    } catch (error) {
      console.error("Error converting files:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app">
      <div className="wrapper">
        <h1>.* &nbsp; to &nbsp; WebP</h1>
        <form onSubmit={handleSubmit}>
          {/* <div className="input-group">
            <label htmlFor="toFormat">Select Format:</label>
            <select
              name="toFormat"
              id="toFormat"
              value={format}
              onChange={handleFormatChange}
            >
              <option value="webp">WebP</option>
              <option value="avif">AVIF</option>
            </select>
          </div> */}
          <div className="upload-group">
            <label className="custom-file-label" htmlFor="file-upload">
              Upload Images
            </label>
            <input
              type="file"
              id="file-upload"
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />

            {files.length > 0 && (
              <p className="file-count">
                {files.length} {files.length === 1 ? "file" : "files"} selected
              </p>
            )}
          </div>

          <div className="input-group">
            <label>Compression Quality: </label>
            <div className="value-group">
              <input
                type="range"
                value={quality}
                onChange={handleQualityChange}
                min="1"
                max="100"
              />
              <input
                type="number"
                value={quality}
                onChange={handleQualityChange}
                min="1"
                max="100"
              />
            </div>
          </div>

          {/* Conditionally disable the button and apply the .disabled class */}
          <button
            type="submit"
            disabled={files.length === 0}
            className={files.length === 0 ? "disabled" : ""}
          >
            Convert
          </button>
        </form>

        {loading && <p className="loading">Loading...</p>}

        {zipFileUrl && (
          <div className="download">
            <h3>Download ZIP File</h3>
            <a href={zipFileUrl} download="images.zip">
              <button>Download ZIP</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
